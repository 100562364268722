import Table from "@material-ui/core/Table";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  TableSortLabel,
} from "@material-ui/core";
import { ListingListView } from "../detail_search_results/listing_list_view/listing_list_view";
import React, { useContext, useEffect, useState } from "react";
import { BrokerSearchingContext } from "../../broker_search_context";
import { SelectedListingsContext } from "../../selected_listings_context";
import { BookmarksContext } from "../../bookmarks_context";
import { ListingSearchContext } from "../../../../components/listing_search/listing_search_context";
import { UpgradePricingModal } from "../detail_search_results/upgrade_pricing_modal/upgrade_pricing_modal";
import { RBCheckbox } from "../../../../components/rb_checkbox/rb_checkbox";

const CustomComponent = React.memo(
  ({ onClear, allIsChecked, onAllSelectedListings, selectedListings }: any) => {
    return (
      <div className='TableHeader-custom-header'>
        <span>{selectedListings.length} Selected</span>
        <RBCheckbox name='' checked={allIsChecked} handleChange={onAllSelectedListings} />
        <span onClick={onClear} className='action--header'>Unselect</span>
      </div>
    )
  }
)

const ListView = React.memo(
  function ListViewComponent(props: IListView) {
    const { searching, setSearching, orderBy, setOrderBy, order, setOrder } =
      useContext(BrokerSearchingContext);
    const { criteria, setCriteria } = useContext(ListingSearchContext);
    const {
      allIsChecked,
      handlePageChange,
      setGroupedListings,
      selectedListings,
      clearSelectedListings,
      handleAllSelectedListings
    } = useContext(SelectedListingsContext);
    const { bookmarks, fetchBookmarks } = useContext(BookmarksContext);
    const { listings, isAddressDisplay, totalListingCount } = props;
    const [showShowUpgradeModal, setShowUpgradePageModal] = useState<boolean>(false);
    const [upgradeListingId, setUpgradeListingId] = useState<any>(null)

    useEffect(() => {
      handlePageChange(listings)

      // perform a cleanup
      return () => {
        setGroupedListings([])
      }
    }, [criteria?.page_number])

    // alignment - 0: left, 1: right
    const headCells = [
      { id: "blank_1", alignment: 0, label: "" },
      { id: "checkbox", alignment: 0, label: "", component: CustomComponent },
      { id: "blank_3", alignment: 0, label: "" },
      { id: "address", alignment: 0, label: "Address" },
      { id: "bedrooms", alignment: 0, label: "Beds" },
      { id: "bathrooms", alignment: 0, label: "Baths" },
      { id: "rent", alignment: 0, label: "Rent" },
      { id: "apt_count", alignment: 0, label: "Units" },
      { id: "date_available", alignment: 0, label: "Available" },
      { id: "dom", alignment: 0, label: 'DOM' },
      { id: "status", alignment: 0, label: "Status" },
      { id: "square_footage", alignment: 0, label: "Sq. Footage" },
      { id: "type_contact", alignment: 0, label: "Type of Contact" },
      { id: "management_co", alignment: 0, label: "Owner/MGMT Name" },
      { id: "blank_4", alignment: 0, label: "" },
      { id: "blank_5", alignment: 0, label: "" }, //Claim button
      { id: "blank_6", alignment: 0, label: "" }, //Share button
    ];

    const handleSortClick = (event, field_name) => {
      const isAsc = orderBy === field_name && order === "asc";
      setOrder(isAsc ? "desc" : "asc");
      setOrderBy(field_name);
      setCriteria({ ...criteria, sort_field: field_name, sort_ascending: isAsc });
      setSearching(true);
      setGroupedListings([])
    };

    const toggleUpgradeModal = (listingId?: any) => {
        setUpgradeListingId(listingId)
        setShowUpgradePageModal(showShowUpgradeModal => !showShowUpgradeModal)
    }

    useEffect(() => {
      if (!showShowUpgradeModal) {
        document.body.style.overflow = 'visible'
        document.body.style.paddingRight = "0px"
      }
    }, [showShowUpgradeModal])

    return (
      <>
        {showShowUpgradeModal && (
          <UpgradePricingModal
            listingId={upgradeListingId}
            showModal={showShowUpgradeModal}
            modalAction={toggleUpgradeModal}
            totalListingCount={totalListingCount}
          />
        )}
        <Table className="search-results-table">
          <TableHead>
            <TableRow>
              {headCells.map((cell) => (
                <TableCell
                  align={cell.alignment === 0 ? "left" : "right"}
                  key={cell.id}
                >
                  {
                    cell.component
                      ? <cell.component
                          selectedListings={selectedListings}
                          onClear={clearSelectedListings}
                          allIsChecked={allIsChecked}
                          onAllSelectedListings={() => handleAllSelectedListings(listings)}
                        />
                      : (
                        <TableSortLabel
                          active={orderBy === cell.id}
                          direction={orderBy === cell.id ? order : "asc"}
                          onClick={(event) => handleSortClick(event, cell.id)}
                        >
                          {cell.label}
                          {orderBy === cell.id ? (
                            <span className="visually-hidden">
                              {order === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </span>
                          ) : null}
                        </TableSortLabel>
                      )
                  }
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {!searching
              ? listings.map((listing) => {
                  return (
                    <ListingListView
                      pageNumber={criteria?.page_number}
                      listing={listing}
                      listings={listings}
                      key={listing.apartment_id}
                      bookmarks={bookmarks}
                      handleBookmark={fetchBookmarks}
                      checked={selectedListings.some(
                        (selectedListing) =>
                          selectedListing.groupListingId === listing.apartment_id
                      )}
                      isAddressDisplay={isAddressDisplay}
                      toggleUpgradeModal={toggleUpgradeModal}
                      showShowUpgradeModal={showShowUpgradeModal}
                    />
                  );
                })
              : null}
          </TableBody>
        </Table>
      </>
    );
  }
)

interface IListView {
  listings: any[];
  isAddressDisplay: boolean
  totalListingCount?: number
  isEnableAllListingsTab?: boolean;
  isEnableMlsListingsTab?: boolean;
  isEnableRbListingsTab?: boolean;
  totalResults?: number
}

export { ListView }
