import React, { useContext, useState, useCallback, useEffect } from "react";
import { getStateAbbr } from "../../../../../utils/get_state_abbr";
import { imgUrl } from "../../../../../../services/constants";
import "./listing_details.scss";
import { FloorPlanDetails } from "../floorplan_details/floorplan_details";
import {IListing} from "../../../../../../services/api/listing";
import { ListingActionButtons } from "../../../../listing_action_buttons/listing_action_buttons";
import { ListingSearchContext } from "../../../../../components/listing_search/listing_search_context";
import { isMobile, ViewType } from "../../../../../utils/device_info";
import { Collapse } from "react-bootstrap";
import { FloorPlanDialog } from "../../shared/floor_plan_dialog";
import { SweetAlertContext } from "../../../../../components/sweet_alert/sweet_alert";
import { RBCheckbox } from "../../../../../components/rb_checkbox/rb_checkbox";
import { SelectedListingsContext } from "../../../selected_listings_context";
import { AgentUserContext } from "../../../agent_user_context";
import { BookmarksContext } from "../../../bookmarks_context";
import moment from "moment";
import { useAgentShareLink } from "../../../../../components/hooks/use_agent_share_link";
import { BrokerSearchingContext } from '../../../broker_search_context';
import { browserName } from "react-device-detect";
import { useTranslation } from "react-i18next";

export const ListingDetails = React.memo(
  function ListingDetailsComponent(props: IListingDetails) {
    const [shareListing, setShareListing] = useState(null);
    const { Dialog, setShareDialogOpen } = useAgentShareLink();

    const [isExpanded, setIsExpanded] = useState(false);
    const [buildingListings, setBuildingListings] = useState([] as IListing[]);

    const { t } = useTranslation( 'translation',{useSuspense: false});
    const { setAlertVisible, setAlertType } = useContext(SweetAlertContext);
    const { getGroupListingUnits, criteria } = useContext(ListingSearchContext);
    const { isFullAgent, isMlsAgent, agentInfo, countryCode } = useContext(AgentUserContext);
    const {
      setGroupedListings,
      handleMergeGroupListings,
      isRBListing,
      isMLSListing,
      isRBGListing,
      handleSelectedListing,
      isMlsAgentAndNonMlsListing,
      getSelectedListingIds,
      isGatewayAccessibleListing,
      isMlsAgentAndEmptyDisclaimer,
      disableCopyPaste,
      rowPerPageRenderer
    } = useContext(SelectedListingsContext);
    const { toggleBookmarks } = useContext(BookmarksContext);
    const { viewType, isMobileRes } = useContext(BrokerSearchingContext);
    const { listing, isAddressDisplay, toggleUpgradeModal, showShowUpgradeModal } = props;

    let url = "";

    if (listing.pics.length > 0) {
      url = `url('${imgUrl}/${listing.pics[0].path}?w=500')`;
    }

    useEffect(() => {
      // perform a cleanup
      return () => {
        setGroupedListings([])
        setBuildingListings([])
      }
    }, [rowPerPageRenderer, criteria?.page_number])

    const onSuccess = () => {
      setAlertType("success");
      setAlertVisible(true);
    };

    const getFloorPlans = async (isNotOpen?: boolean) => {
      const listings = await getGroupListingUnits(listing, agentInfo.super_region);
      setBuildingListings(listings);
      if (isNotOpen) handleMergeGroupListings(listings, props.pageNumber)
    };

    const toggleFloorPlansDialog = () => {
      if (listing.apt_count <= 1) {
        return;
      }

      setIsExpanded(!isExpanded);
      if (!isExpanded) {
        getFloorPlans();
      }
    };

    const isBookmarked = (listingId) => {
      return (
        props.bookmarks.findIndex(
          (bookmark) => bookmark.address == listing.address
        ) > -1
      );
    };

    const handleClick = (listing) => {
      if (isFullAgent() || isGatewayAccessibleListing(listing)) {
        if (listing.apt_count > 1) {
          toggleFloorPlansDialog();
        } else {
          const url = `/broker_portal/listing_details/${listing.apartment_id}`;
          window.open(url, "_blank");
        }
      } else if (
        isMlsAgentAndNonMlsListing(listing) ||
        isMlsAgentAndEmptyDisclaimer(listing)
      ) {
        if (listing.apt_count > 1) {
          toggleFloorPlansDialog();
        }
      }
    };

    const clickAddressHandler =  useCallback(() => {
            if (isAddressDisplay) {
                if (listing.apt_count >  1) {
                    handleRowCheck()
                } else {
                    toggleUpgradeModal(listing.apartment_id)
                }
            } else {
            window.location.href = "/agent/subscriptions_and_payment"
            }
    }, [listing])

    const getActionButtons = (listing) => {

      if (
        isMlsAgentAndNonMlsListing(listing) ||
        isMlsAgentAndEmptyDisclaimer(listing)
      ) {
        return (
          <div className="col-6">
            <ListingActionButtons
              selectedListingIds={[listing.apartment_id]}
              onSuccess={onSuccess}
              excludeBookmark={true}
              toggleBookmarks={toggleBookmarks}
              isDisabled={false}
              listing={listing}
              includeFacebookShare={false}
              excludeClaim={isRBGListing(listing)}
              excludeCMA={true}
              excludeEmail={true}
              excludePrint={true}
              excludeShare={true}
            />
          </div>
        );
      } else {
        const selectedListingIds = getSelectedListingIds();
        return (
          <div className="col-6">
            <ListingActionButtons
              selectedListingIds={selectedListingIds}
              onSuccess={onSuccess}
              excludeBookmark={isBookmarked(listing.apartment_id)}
              toggleBookmarks={toggleBookmarks}
              isDisabled={false}
              listing={listing}
              includeFacebookShare={true}
              excludeClaim={isRBGListing(listing)}
              excludeCMA={false}
              excludeEmail={false}
              excludePrint={false}
              excludeShare={false}
            />
          </div>
        );
      }
    };

    const handleShareClick = (e) => {
      if (listing.apt_count > 1) {
        return;
      } else {
        e.stopPropagation();
        setShareDialogOpen(true);
        setShareListing(listing);
      }
    };

    const showOrHideAddress = (listing) => {
      if (
        isMlsAgentAndNonMlsListing(listing) ||
        isMlsAgentAndEmptyDisclaimer(listing)
      ) {
        return (
          <div
            onPaste={disableCopyPaste}
            onCopy={disableCopyPaste}
            ref={listingAddress => {
                  listingAddress  && listingAddress.addEventListener('selectstart', (e) => e.preventDefault())
              }}
            className="listing-full-address"
            onClick={clickAddressHandler}
          >
            <div>
              {isAddressDisplay && `${listing.address},`} {listing.city}, {getStateAbbr(listing.state, countryCode)} {listing.zip}
              {isBookmarked(listing.apartment_id) && listing.apt_count <= 1 ? (
                <i
                  onClick={(e) => toggleBookmarks(e, [listing.apartment_id])}
                  className="fas fa-bookmark title--bookmark"
                />
              ) : null}
            </div>
            {!isAddressDisplay && (
              <div className="upgrade--text">
              <a href="/agent/subscriptions_and_payment">Upgrade</a> to see
                address
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div
            onPaste={e => !isFullAgent() && disableCopyPaste(e)}
            onCopy={e => !isFullAgent() &&  disableCopyPaste(e)}
            ref={listingAddress => {
                  !isFullAgent() &&  listingAddress  && listingAddress.addEventListener('selectstart', (e) => e.preventDefault())
            }}
            className={`${!isFullAgent() && 'listing-full-address'}`}
          >
            {listing.address}, {listing.city}, {getStateAbbr(listing.state, countryCode)}{" "}
            {listing.zip}
            {isBookmarked(listing.apartment_id) && listing.apt_count <= 1 && isMLSListing(listing) ? (
              <i
                onClick={(e) => toggleBookmarks(e, [listing.apartment_id])}
                className="fas fa-bookmark title--bookmark"
              />
            ) : null}
          </div>
        );
      }
    };

    const showOrHideRBDisclaimer = (listing) => {
      if (isRBListing(listing)) {
        return (
          <div className="review-checkbox-detail mt-1">
            <img
              src={`${imgUrl}/icons/rb-verified.png`}
              className="rb-check"
              alt="rental beast reviewed"
            />

          </div>
        );
      }
    };

    const handleRowCheck = () => {
      if (listing.apt_count <= 1) {
        handleSelectedListing(
          listing.apartment_id,
          !props.checked,
          listing.apartment_id,
          props.listings,
          criteria?.page_number
        );
      } else {
        toggleFloorPlansDialog();
      }
    };

    const showOrHideShareLinkIcon = (listing) => {
      if (
        (isFullAgent() && listing.is_listing_free) ||
        listing.is_agent_listing
      ) {
        return (
          <i
            className="fas fa-share share-link-tile"
            onClick={handleShareClick}
          />
        );
      } else if (isMlsAgent() && listing.is_agent_listing) {
        return (
          <i
            className="fas fa-share share-link-tile"
            onClick={handleShareClick}
          />
        );
      }
    };

    useEffect(() => {
      if (showShowUpgradeModal) {
        setIsExpanded(false)
      }
      if (!showShowUpgradeModal) {
        document.body.style.overflow = 'visible'
        document.body.style.paddingRight = "0px"
      }

      return () => {
        setIsExpanded(false)
      }
    }, [showShowUpgradeModal])

    const handleToggleUpgradeModal = (listingId?: any) => {
      toggleUpgradeModal(listingId)
    }

    useEffect(() => {
        if (viewType === ViewType.TILE) {
            const fragment = document.createDocumentFragment();
            const element = document.querySelector('.tilebox')
            while (element.firstChild) {
              fragment.appendChild(element.firstChild);
            }
            element.parentNode.replaceChild(fragment, element);
        }
    },[viewType])

    const getRentChange = () => {
      const rentChanges = listing.rent_changes
      const rentChangeVaue = ((rentChanges || [])[0] || {})
      const rentChangeAbsolute = rentChangeVaue?.absolute > 0
      let rentChange: any = null
      const arrowStyle: any = {
        fontWeight: 'bold',
        fontSize: '140%',
        margin: "-7px 0px 0px 5px",
        position: 'absolute'
      }

      if (rentChangeVaue?.absolute) {
        if (rentChangeAbsolute) rentChange = <span style={{ ...arrowStyle, color: '#81C784' }}>&uarr;</span>
        else rentChange = <span style={{ ...arrowStyle, color: 'red' }}>&darr;</span>
      }

      return listing.apt_count > 1 ? null : rentChange
    }

    const listingSourceType = (listing) => {
        if (isMLSListing(listing)) {
            return (
                <div className="mls__container--counter">
                    <span>{t('MLS')}</span>
                </div>
            )
        }
        if (isRBGListing(listing)) {
            return (
            <div className="rbg__container--counter">
                <span className="row-rbg">RBG</span>
            </div>
            )
        }
    }

    return (
      <div key={listing.apartment_id} className={`detail--row ${viewType === ViewType.MAP && !isMobileRes ? "map" : ""} col-lg-6`}>
        <div
          className={`col-12 tile-box ${viewType === ViewType.MAP && !isMobileRes ? "map" : ""} pl-0 pr-0`}
          onMouseEnter={() => {
           props.hoverListingAction && props.hoverListingAction(listing.apartment_id)
          }}
          onMouseLeave={() => {
            props.hoverListingAction &&  props.hoverListingAction(null);
          }}
        >
          <div className="row tilebox">
            <div className={`col-12 col-lg-12 ${viewType === ViewType.MAP ? "col-md-10 col-xl-10 pl-3" : "col-md-4 col-xl-4 pl-0"} pr-0`}>
              <div className={`pictures__module ${viewType === ViewType.MAP && isMobileRes ? "map-mobile" : ""}`}
                onClick={() => {
                  handleClick(listing);
                }}
              >
                <div className="picture__container">
                  <div
                    className={`picture__container--bg ${viewType === ViewType.MAP && !isMobileRes ? "map" : ""}`}
                    style={{ backgroundImage: url }}
                  >
                    <div className="picture__container--counter">
                      <i className="fas fa-camera" />
                      <span>{listing.pics.length}</span>
                    </div>
                    {listingSourceType(listing)}
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-12 col-lg-12 col-xl-8 pl-4">
              <div className="row"
                onClick={() => {
                  handleClick(listing);
                }}
              >
                <div
                  className={`address__container ${viewType === ViewType.MAP && !isMobileRes ? "map" : ""} col-12`}
                  style={listing.apt_count == 1 ? { cursor: "pointer" } : null}
                >
                  {isMlsAgentAndNonMlsListing(listing) ||
                  isMlsAgentAndEmptyDisclaimer(listing) ? null : (
                    <RBCheckbox
                      name={listing.apartment_id.toString()}
                      checked={props.checked}
                      handleChange={() => handleRowCheck()}
                    />
                  )}
                  <div>{listing.building_name}</div>
                  {showOrHideAddress(listing)}

                </div>
                <div className={`col-12 ${viewType === ViewType.MAP && !isMobileRes ? "map-rbicon" : ""}`}>
                  {showOrHideRBDisclaimer(listing)}
                </div>
              </div>
              <div className="row"
                onClick={() => {
                  handleClick(listing);
                }}
              >
                <div className={`col-6 col-md-3 col-lg-6 ${viewType === ViewType.MAP && !isMobileRes ? "listing-label" : ""}`}>
                  <div className="feature--label">
                    Beds:{" "}
                    {listing.min_beds === listing.max_beds
                      ? listing.min_beds
                      : `${listing.min_beds} - ${listing.max_beds}`}
                  </div>
                  <div className="feature--label">
                    Baths:{" "}
                    {listing.min_baths === listing.max_baths
                      ? listing.min_baths
                      : `${listing.min_baths} - ${listing.max_baths}`}
                  </div>
                  <div className={`feature--label ${viewType === ViewType.MAP && !isMobileRes ? "rent-label" : ""}`}>
                    Rent:{" "}
                    {listing.min_rent === listing.max_rent
                      ? "$" + listing.min_rent
                      : `$${listing.min_rent} - $${listing.max_rent}`} {getRentChange()}
                  </div>
                  {/*<div className="feature--label">Type of Contact: {getOwnerLabel(listing)}</div>*/}
                </div>
                <div className={`col-6 col-md-4 col-lg-6 ${viewType === ViewType.MAP && !isMobileRes ? "listing-label" : ""}`}>
                  <div className="feature--label">Status: {listing.status}</div>
                  {listing.apt_count === 1 ? (
                    <div className="feature--label">
                      Available: {moment(listing.date_available).format("MM/DD/YY")}
                    </div>
                  ) : null}
                  <div className="feature--label">DOM: {listing.dom?.length ? listing.dom[0]?.calculated_days_on_market : 0}</div>
                </div>
              </div>

              <div className={`
                    row action-buttons-wrapper
                    ${viewType === ViewType.MAP ? "map-buttons" : ""}
                    ${viewType === ViewType.MAP && browserName === 'Mobile Safari' ? "mobile-safari" : ""}
              `}>
                {listing.apt_count > 1 ? (
                  <div className="col-6"
                    onClick={() => {
                      handleClick(listing);
                    }}
                  >
                    <div className={`floorplans--button ${viewType === ViewType.MAP && !isMobileRes ? "floorplan-btn" : ""}`}>
                      {isExpanded ? (
                        <span>
                          Hide Floorplans <i className="fas fa-chevron-up" />
                        </span>
                      ) : (
                        <span>
                          Show Floorplans <i className="fas fa-chevron-down" />
                        </span>
                      )}
                    </div>
                  </div>
                ) : (
                  getActionButtons(listing)
                )}
                <div className="col share-icon-container" style={{visibility: viewType === ViewType.MAP ? "hidden" : "visible"}}>
                  {showOrHideShareLinkIcon(listing)}
                </div>
              </div>
            </div>
          </div>
        </div>
        {!isMobile() ? (
          <FloorPlanDialog
            pageNumber={props.pageNumber}
            isExpanded={isExpanded}
            setIsExpanded={setIsExpanded}
            buildingListings={buildingListings}
            listing={listing}
            isAddressDisplay={isAddressDisplay}
            isFullAgent={isFullAgent}
            toggleUpgradeModal={toggleUpgradeModal}
          />
        ) : (
          <Collapse in={isExpanded} timeout={200}>
            <section>
              <FloorPlanDetails
                listings={buildingListings}
                groupListingId={listing.apartment_id}
                handleToggleUpgradeModal={handleToggleUpgradeModal}
                isAddressDisplay={isAddressDisplay}
              />
            </section>
          </Collapse>
        )}
        <Dialog shareListing={shareListing} />
      </div>
    );
  }
)
export interface IListingDetails {
    listing: IListing;
    listings: IListing[];
    pageNumber?: number;
    onMergeGroupListings?: (listings: IListing[], pageNumber: number) => any;
    handleBookmark: () => void;
    bookmarks: { apartment_id: number; address: string }[];
    checked: boolean;
    isAddressDisplay: boolean;
    toggleUpgradeModal: (listingId?: any) => void;
    showShowUpgradeModal: boolean;
    hoverListingAction?: (number) => void
}
