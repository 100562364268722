import { REST } from "../rest";
import { apiURL, listingSearchUrl } from "../constants";
import { parseOwnerPayValue } from "../../src/features/listing_management/utils";
import { getAuthToken } from "../session";
import { ILocationObj } from "../../src/components/listing_search/listing_search_context";
import { stateList } from "../../src/components/listing_search/utilities";

const API_URL = apiURL + "/v1";
const LISTING_SEARCH_URL = '/api/listing';

export const listingApi = {
  post: async (partner, settings: IListing, allowedKeys = []) => {
    const formData = new FormData();
    const saveParams = mapCriteriaToApiPutParams(settings, allowedKeys);
    Object.keys(saveParams).forEach((settingKey) => {
      settings[settingKey] && formData.append(settingKey, saveParams[settingKey]);
    });
    return await REST.post(
      `${API_URL}/listing.json`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          partner_key: partner,
        },
      },
      true
    ).then((res) => {
      return res;
    });
  },
  put: async (partner, listingId, settings: IListing, allowedKeys = []) => {
    const formData = new FormData();
    formData.append("listing_id", listingId);

    let stage_changes_for_approval =
      settings?.status === "Active" ||
      settings?.status === "Application Pending";
    const saveParams = mapCriteriaToApiPutParams(settings, allowedKeys);
    allowedKeys.includes("stage_changes_for_approval")
      ? (saveParams.stage_changes_for_approval = stage_changes_for_approval)
      : null;
    Object.keys(saveParams).forEach((settingKey) => {
      formData.append(settingKey, saveParams[settingKey]);
    });

    return await REST.put(
      `${API_URL}/listing.json`,
      formData,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
        params: {
          partner_key: partner,
        },
      },
      true
    ).then((res) => {
      return res;
    });
  },
  get: async (
    params: IListingApiGetParams
  ): Promise<IListingApiGetResponse> => {
    const authToken = await getAuthToken();
    if(params.state_list === null || params.state_list === undefined){
      params.state_list = await stateList();
    }
    const searchCriteria = mapCriteriaToApiGetParams(params);
    return await REST.get(
      `${LISTING_SEARCH_URL}`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token token=${authToken}`,
        },
        params: searchCriteria,
      },
      true,
      "broker_search_web"
    ).then((res) => {
      return res;
    });
  },
  getGroupedListings: async (params): Promise<IListingApiGetResponse> => {
    const authToken = await getAuthToken()
    const queryStringified = new URLSearchParams(params).toString()

    return await REST.get(
      `${LISTING_SEARCH_URL}?${queryStringified}`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Token token=${authToken}`,
        }
      },
      true,
      'broker_search_web'
    ).then((res) => {
      return res
    })
  },
  getRentalLeaseDetails: async (
    listingIdParam: IListingIdParam
  ): Promise<IListingApiGetResponse> => {
    const authToken = await getAuthToken();
    return await REST.get(
      `${API_URL}/listing_status_tracker.json`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token token=${authToken}`,
        },
        params: listingIdParam,
      },
      true,
      "broker_search_web"
    ).then(({ data }) => data);
  },
  getCmaStatsCalculation: async (
    listingIdsParam: IListingApiGetParams
  ): Promise<IListingApiGetResponse> => {
    const authToken = await getAuthToken();
    const listingIds = mapCriteriaToApiGetParams(listingIdsParam);

    return await REST.get(
      `${API_URL}/listing_cma_stats.json`,
      {
        headers: {
          "Content-Type": "multipart/form-data",
          Authorization: `Token token=${authToken}`,
        },
        params: listingIds,
      },
      true,
      "broker_search_web"
    ).then(({ data }) => {
      return data
    });
  },
  getListingsInShape: async (params: IListingApiGetParams, payload) => {
      const authToken = await getAuthToken();
      params.state_list = await stateList();
      const searchCriteria = mapCriteriaToApiGetParams(params);
      return await REST.post(
        `${listingSearchUrl}`,
        payload,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Token token=${authToken}`,
          },
            params: searchCriteria,
        },
        true,
          "broker_search_web"
      ).then((res) => {
        return res;
      }).catch(err => console.log('err listing shape>>', err));
    },
  };

export const listingJsonApi = {
  get: async (params: IListingApiGetParams): Promise<IListingApiGetResponse> => {
      const authToken = await getAuthToken();
      let userId = sessionStorage.getItem('user-id');
      if (userId == 'null' || userId === null){
        sessionStorage.setItem('user-id', ''+params.agent_id);
      }
      params.state_list = await stateList();
      const searchCriteria = mapCriteriaToApiGetParams(params);
      return await REST.get(
          `${API_URL}/listing.json`,
          {
            headers: {
              "Content-Type": "multipart/form-data",
              Authorization: `Token token=${authToken}`,
            },
            params: searchCriteria,
          },
          true,
          "broker_search_web"
      ).then((res) => {
        return res;
      });
    },
}

const mapCriteriaToApiPutParams = (params: IListing, allowedKeys): any => {
  const payload = {
    ...params,
    // ac_type: stringifyArrayParam(params, "ac_type"),
    laundry: stringifyArrayParam(params, "laundry"),
    // furnished: stringifyArrayParam(params, "furnished"),
    // building_type: stringifyArrayParam(params, "building_type"),
    external_structures: stringifyArrayParam(params, "external_structures"),
    // heat_type: stringifyArrayParam(params, "heat_type"),
    listing_ids: stringifyArrayParam(params, "listing_ids"),
    listing_type: getPrimitiveParamValue(params, "listing_type"),
    // owner_pay: stringifyArrayParam(params, "owner_pay"),
    parking_options: stringifyArrayParam(params, "parking_options"),
    pet_types: stringifyArrayParam(params, "pet_types"),
    floor_types: stringifyArrayParam(params, "floor_types"),
    property_features: stringifyArrayParam(params, "property_features"),
    utilities_available: stringifyArrayParam(params, "utilities_available"),
    udf: stringifyArrayParam(params, "udf"),
    utilities_included: stringifyArrayParam(params, "utilities_included"),
    unit_features: stringifyArrayParam(params, "unit_features"),
    floors_in_unit: stringifyArrayParam(params, "floors_in_unit"),
    room_types: stringifyArrayParam(params, "room_types"),
    move_in_specials_tenant: stringifyArrayParam(
      params,
      "move_in_specials_tenant"
    ),
    move_in_costs: stringifyArrayParam(params, "move_in_costs"),
    listings_aj_optional_fields: stringifyArrayParam(params, "listings_aj_optional_fields"),
    rent: params.rent?.toString().replace(/\D+/g, "") || "0",
    // stage_changes_for_approval: true
  };

  if (allowedKeys.length > 0) {
    const cleanPayload = {};

    allowedKeys.forEach((allowedKey) => {
      if (payload[allowedKey] !== undefined) {
        cleanPayload[allowedKey] = payload[allowedKey];
      }
    });

    return cleanPayload;
  }

  return payload;
};

export const mapCriteriaToApiGetParams = (params: IListingApiGetParams) => {
   const payload = {
    ...params,
    addresses: stringifyArrayParam(params, "addresses"),
    ac_type: stringifyArrayParam(params, "cooling_type_list"),
    building_type: stringifyArrayParam(params, "building_type_list"),
    floors_in_unit: stringifyArrayParam(params, "floors_in_unit_list"),
    // property_structure: stringifyArrayParam(params, "property_structure_list"),
    property_owner_type: stringifyArrayParam(
      params,
      "ownership_type_list"
    ),
    contract_type: stringifyArrayParam(params, "contract_type_list"),
    // city: getLocationParamValue(params, "city"),
    days_on_market: convertIntObjRange(params, 'days_on_market'),
    direct_phone: getPrimitiveParamValue(params, "direct_phone"),
    external_structures: stringifyArrayParam(params, "external_structures"),
    fields_to_include:
      (params.fields_to_include && params.fields_to_include.length) > 0
        ? JSON.stringify(params.fields_to_include)
        : null,
    finished_square_footage: convertIntObjRange(params, 'finished_square_footage'),
    floor_types: stringifyArrayParam(params, "floor_type"),
    furnished: getPrimitiveParamValue(params, "furnished"),
    garage_spaces: getPrimitiveParamValue(params, "garage_spaces"),
    heat_type: stringifyArrayParam(params, "heat_type_list"),
    laundry: stringifyArrayParam(params, "laundry_type_list"),
    lease_rate: convertIntObjRange(params, 'lease_rate'),
    listing_ids: stringifyArrayParam(params, "listing_ids"),
    listing_type: getPrimitiveParamValue(params, "listing_type"),
    lot_square_footage: convertIntObjRange(params, "lot_square_footage"),
    // metro: getLocationParamValue(params, "metro"),
    max_bathrooms: getBathroomRangeParamValue(params, "max_bathrooms"),
    max_bedrooms: getBedroomRangeParamValue (params, "max_bedrooms"),
    max_price: getRentRangeParamValue(params, "max_price"),
    max_sq_ft: getSqFtRangeParamValue(params, "max_sq_ft"),
    min_bedrooms: getBedroomRangeParamValue(params, "min_bedrooms"),
    min_bathrooms: getBathroomRangeParamValue(params, "min_bathrooms"),
    min_pic_count: 1 === params.min_pic_count ? 1 : null,
    min_price: getRentRangeParamValue(params, "min_price"),
    min_sq_ft: getSqFtRangeParamValue(params, "min_sq_ft"),
    // neighborhood: getLocationParamValue(params, "neighborhood"),
    multiple_areas: stringifyArrayParam(params, "multiple_areas"),
    owner_ids: stringifyArrayParam(params, "owner_ids"),
    owner_pay: stringifyArrayParam(params, "owner_pay_list"),
    // ownership_type: stringifyArrayParam(params, "ownership_type_list"),
    parking_options: stringifyArrayParam(params, "parking_options_list"),
    pet_types: stringifyArrayParam(params, "pet_types_list"),
    property_features: stringifyArrayParam(params, "property_features"),
    public_transit: getPublicTransitParam(params),
    rb_mls_id: getPrimitiveParamValue(params, "rb_mls_id"),
    renter_insurance: getPrimitiveParamValue(params, "renter_insurance"),
    room_types: stringifyArrayParam(params, "room_types"),
    statuses: stringifyArrayParam(params, "statuses_list"),
    state: stringifyArrayParam(params,"state_list"),
    // sub_neighborhood: getLocationParamValue(params, "sub_neighborhood"),
    // type_contact: stringifyArrayParam(params, "ownership_type_list"),
    unit_features: stringifyArrayParam(params, "unit_features_list"),
    utilities_available: stringifyArrayParam(params, "utilities_available"),
    virtual_showing_allowed: getPrimitiveParamValue(params, "virtual_showing_allowed"),
    year_built: getPrimitiveParamValue(params, "year_built"),
    // zip: getLocationParamValue(params, "zip"),

  };

  // delete the members of the criteria object that are solely for FE use (UI state control)
  const fieldsToDelete = [
    "building_type_list",
    "floors_in_unit_list",
    "cooling_type_list",
    "floor_type",
    "heat_type_list",
    "laundry_type_list",
    "owner_pay_list",
    "ownership_type_list",
    "parking_options_list",
    "pet_types_list",
    "rent_range",
    "sqft_range",
    "statuses_list",
    "unit_features_list",
    "state_list"
  ];

  if (!payload.garage) {
    fieldsToDelete.push("garage");
  }

  fieldsToDelete.forEach((key) => {
    delete payload[key];
  });

  return payload;
};

export const stringifyArrayParam = (
  params: IListingApiGetParams | IListing,
  paramName
) => {
  return (params[paramName] && params[paramName].length) > 0
    ? JSON.stringify(params[paramName])
    : [];
};

const getPrimitiveParamValue = (
  params: IListingApiGetParams | IListing,
  paramName
) => {
  return params[paramName] &&
    "" !== params[paramName] &&
    -1 !== params[paramName]
    ? params[paramName]
    : null;
};

const getBathroomRangeParamValue = (params, paramName) => {
  if (!params["bathroom_range"]) {
    return;
  }
  const val = params["bathroom_range"][paramName];
  return val !== "" ? val : null;
};

const getBedroomRangeParamValue = (params, paramName) => {
  if (!params["bedroom_range"]) {
    return;
  }
  const val = params["bedroom_range"][paramName];
  return val !== "" ? val : null;
};

export const getPublicTransitParam = (params: IListingApiGetParams) => {
  const types = params.public_transit?.types;
  const distance = params.public_transit?.distance;

  if (!types?.length || distance <= 0) {
    return null;
  }

  return JSON.stringify({
    distance: distance,
    types: types,
  });
};

const getRentRangeParamValue = (params, paramName) => {
  if (!params["rent_range"]) {
    return;
  }
  const val = params["rent_range"][paramName];
  return val !== "" ? val : null;
};

const getSqFtRangeParamValue = (params, paramName) => {
  if (!params["sqft_range"]) {
    return;
  }
  const val = params["sqft_range"][paramName];
  return val !== "" ? val : null;
};

const convertIntObjRange = (
    params: IListingApiGetParams | IListing,
    paramName
) => {
  if (params && params[paramName] && (Object.keys(params[paramName]).length > 0)) {
    const res = {}
    Object.keys(params[paramName]).forEach((key) => {
      const parsed = parseInt(params[paramName][key]);
      res[key] = isNaN(parsed) ? 0 : parsed
    })
    return res;
  }
  else {
    return;
  }
};

/**
 * This function will take a parameter that can be either a String or an Array
 * @params search parameters object
 * @paramName key of search field parameter
 * @returns If the param is a string it returns the string, if it's an array it will return a stringified array
 */
export const getArrayOrStringParamValue = (params, paramName) => {
  if (Array.isArray(params[paramName])) {
    return stringifyArrayParam(params, paramName);
  } else {
    return getPrimitiveParamValue(params, paramName);
  }
};


//Returned

interface IListingIdParam {
  listing_id: number;
}
export interface IListing {
  apply_token_url?: string;
  allow_syndication?: boolean;
  ac_type?: any;
  address?: string;
  agent_info?: {
    agent_mls_id: string;
    agent_type: string;
    agent_user_id: number;
    email: string;
    ext_agent_id: number;
    full_name: string;
    phone: string;
    nrds_id: string;
    partner_key: string;
  };
  air_conditioning?: boolean;
  apartment_id?: number;
  application_procedure?: string;
  apt_number?: string;
  apt_count?: number;
  account?: string;
  agent_listing_notes?: any; //Read is object, write is string https://www.pivotaltracker.com/story/show/165564276
  area?: string;
  base_melissa_address_key?: string;
  bathrooms?: string;
  bedrooms?: string;
  beds_count?: number;
  best_time?: string;
  broker_notes?: string;
  building_name?: string;
  building_type?: string;
  property_structure?: string;
  property_owner_type?: string;
  contract_type?: string;
  cable?: boolean;
  city?: string | string[];
  comments?: string;
  compensation_remarks?: string;
  condo_association_name?: string;
  contact?: string;
  county?: string;
  crossstreet?: string;
  date_available?: string;
  date_active?: { min: string; max: string };
  deleaded?: string;
  disclaimer?: IListingDisclaimer;
  disclosure?: string;
  dishwasher?: boolean;
  direct_phone?: string;
  direct_phone2?: string;
  direct_phone3?: string;
  docs?: any[];
  doorman?: boolean;
  dom?: any;
  electricity?: boolean;
  elevator?: boolean;
  email?: string;
  exclusive_by?: string;
  external_structures?: string[];
  expiration_date?: string;
  fax?: string;
  feed_building_name?: string;
  financial_reqs?: string;
  first_name?: string;
  fitness?: string;
  floor?: string;
  floor_plan_name?: string;
  floor_plan_id?: number;
  floor_plan_min_rent?: number;
  floor_plan_max_rent?: number;
  floor_plan_min_squarefootage?: number;
  floor_plan_max_squarefootage?: number;
  floor_plan_units_available?: number;
  floor_type?: any;
  full_bathrooms?: string;
  furnished?: string;
  half_bathrooms?: string;
  health_care?: string;
  heat?: boolean;
  heat_type?: any;
  hide_for_market_bp?: boolean;
  hide_partner_cp?: boolean;
  hide_rb_cp?: boolean;
  hoa_fee?: string;
  hot_water?: boolean;
  internet?: boolean;
  is_agent_listing?: boolean;
  is_listing_free?: boolean;
  is_featured?: boolean;
  is_saved_for_user?: boolean;
  la_reviewer?: boolean;
  landlord_application_review?: boolean;
  landlord_name?: string;
  landlord_phone?: string;
  landlord_email?: string;
  last_name?: string;
  latitude?: number;
  laundry?: string;
  lease_date?: string;
  lease_term?: number;
  lease_term_details?: string;
  listing_agent_id?: string;
  listing_source_type?: string;
  long_term_commission?: number;
  longitude?: number;
  main_pic?: string;
  management_co?: string;
  max_baths?: number;
  max_beds?: number;
  max_rent?: number;
  metro_id?: number;
  melissa_address_key?: string;
  mgmt_address?: string;
  min_baths?: number;
  min_beds?: number;
  min_rent?: number;
  mls_id?: string;
  mls_entry_date?: string;
  monthly_rate?: number;
  move_in_costs?: string;
  move_in_specials?: string;
  neighborhood_id?: string;
  on_market_date?: string;
  open_house_schedule?: string;
  other_parking?: string;
  other_utility?: string;
  other_application_instructions?: string;
  owner_expenses?: string;
  owner_id?: number;
  owner_pay?: string;
  owner_pay_amount?: string;
  parking_fee?: 0;
  parking_options?: string;
  partner_private_notes?: string;
  pets?: boolean;
  pet_fee?: string;
  pet_specs?: string;
  pet_types?: PetType[];
  pic_count?: number;
  pics?: { id: string; path: string }[];
  pool?: boolean;
  property_features?: string[];
  proxy_application_review?: boolean;
  region?: string;
  region_list?: string;
  rent?: number;
  renter_insurance?: string;
  room_types?: string[];
  short_term?: boolean;
  showing_instructions_listing?: string; //Apartment Showing Instructions
  showing_instructions?: string; //building
  square_footage?: number;
  status?: StatusType;
  state?: string;
  state_list?: string[];
  sublet?: string;
  sublet_term?: string;
  sundeck_grills?: boolean;
  syndication_allow_address?: boolean;
  syndication_targets?: any[];
  third_party_application_url?: string;
  three_quarter_bathrooms?: string;
  type?: string;
  type_contact?: number;
  unit_occupied?: boolean;
  updated_at?: string;
  use_apply_now?: boolean;
  use_paper?: boolean;
  use_third_party?: boolean;
  utilities_included?: string | string[];
  utilities_available?: string | string[];
  virtual_tour_url?: boolean;
  virtual_showing_allowed?: string; //Yes, No, Unknown
  virtual_showing_notes?: string;
  water?: boolean;
  wheel_chair?: boolean;
  zip?: number;
  lot_square_footage?: {min: any; max: any};
  restrictions?: string;
  year_built?: string;
  listing_agent_id_other?: string;
  stage_changes_for_approval?: boolean;
  listing_changes?: {
    has_pending_changes?: boolean;
    pending_edits?: any;
  };
  co_agent_info?: {
    agent_mls_id: string;
    agent_type: string;
    agent_user_id: number;
    email: string;
    ext_agent_id: number;
    full_name: string;
    phone: string;
    nrds_id: string;
  };
  agent_notes?: string;
  rent_changes?: any;
  activity_type?: string;
  neighborhood?: string | string[];
  neighborhood_override?: string | string[];
  neighborhood_override_id?: string;
}

interface IListingDisclaimer {
  rb_verified: boolean;
  tile_disclaimer: string;
  disclaimer_list: string;
}

export interface IListingApiGetParams extends IListing {
  addresses?: string[];
  agent_id?: number;
  attached_to_another_dwelling?: string;
  available_by_date?: string;
  available_from_date?: string;
  active_alerts?: boolean;
  bathroom_range?: { min_bathrooms: any, max_bathrooms: any };
  bedroom_range?: { min_bedrooms: any, max_bedrooms: any };
  building_type_list?: string[];
  cooling_type_list?: any;
  contract_type_list?: string[];
  days_on_market?: {min: any; max: any};
  direct_phone?: string;
  elementary_school?: string
  external_structures?: any;
  fields_to_include?: EFieldsToInclude[];
  finished_square_footage?: {min: any; max: any};
  floors_in_unit_list?: string[];
  furnished?: string;
  garage?: boolean;
  garage_spaces?: number;
  group_by_address?: boolean;
  heat_type_list?: any;
  high_school?: string;
  is_for_consumer?: boolean;
  in_unit_laundry?: boolean;
  include_disclaimer?: boolean;
  landlord_application_review?: boolean;
  landlord_name?: string;
  landlord_phone?: string;
  landlord_email?: string;
  laundry_type_list?: string[];
  lease_rate?: {min: any; max: any};
  listing_type?: ListingType;
  listing_ids?: number[];
  lease_date?: { min: string; max: string };
  max_bathrooms?: number;
  max_bedrooms?: number;
  max_price?: number;
  max_record_count?: number;
  max_sq_ft?: number;
  metro?: string;
  middle_school?: string;
  min_bathrooms?: number;
  min_bedrooms?: number;
  min_commission?: number;
  min_pic_count?: number;
  min_price?: number;
  min_sq_ft?: number;
  multiple_areas?: ILocationObj[];
  owner_ids?: number[];
  owner_pay_amount?: string;
  owner_pay?: string;
  ownership_type_list?: OwnershipType[];
  only_photos?: boolean;
  owner_pay_list?: string[];
  page_number?: number; //Pagination control
  parking_options_list?: string[];
  partner_key?: string; // partner_key must be present in order to avoid queries encompassing the entire database, which will likely timeout
  pet_types_list?: string[];
  property_features?: any;
  property_structure_list?: string[];
  property_owner_type_list?: string[];
  proxy_application_review?: boolean;
  public_transit?: IPublicTransit;
  rent_range?: { min_price: any; max_price: any };
  renter_insurance?: string;
  rb_mls_id?: string;
  rb_portal?: string;
  room_types?: string[];
  school_district?: string;
  sort_field?: string;
  sort_ascending?: boolean;
  statuses?: string;
  statuses_list?: string[];
  state?: string;
  state_list?: string[];
  sub_neighborhood?: string;
  super_region?: number;
  sqft_range?: { min_sq_ft: any; max_sq_ft: any };
  unit_features_list?: string[];
  use_apply_now?: boolean;
  utilities_available?: string[];
  user_id_of_owner?: number;
  virtual_showings?: string;
  virtual_tour?: boolean;
}

export enum ListingType {
  mls = "mls",
  rb = "rb",
  agency_exclusive = "agency_exclusive",
  agent_branded = "agent_branded",
  agent_listings = "agent_listings",
  all_listings = "all_listings",
  other_agent_listings = "other_agent_listings", // FE value only, not used as API param value
}

export enum Furnished {
  furnished = "furnished",
  furnished_with_fee = "furnished_with_fee",
  partially_furnished = "partially_furnished",
  not_furnished = "not_furnished", // FE value only, not used as API param value
}

export enum OwnershipType {
  MANAGEMENT_COMPANY = "Management Company",
  PRIVATE_OWNER = "Private Owner",
  AGENT = "Agent",
}

export enum StatusType {
  ACTIVE = "Active",
  PENDING_NEW = "Pending New",
  RENTED = "Rented",
  APPLICATION_PENDING = "Application Pending",
  DRAFT = "Draft",
}

export interface IPublicTransit {
  distance: number;
  types: any[];
}

export enum LaundryType {
  inUnit = "In-Unit",
}

export enum PetType {
  DOG = "dog",
  CAT = "cat",
  CATS_DOGS = "cats/dogs",
  ANY = "any",
  OTHER = "other",
}

export enum EFieldsToInclude {
  neighborhood_override = "neighborhood_override",
  neighborhood_override_id = "neighborhood_override_id",
  ac_type = "ac_type",
  account = "account",
  additional_monthly_charge = "additional_monthly_charge",
  additional_monthly_charge_comments = "additional_monthly_charge_comments",
  address = "address",
  agent_info = "agent_info",
  agent_listing_notes = "agent_listing_notes", //Read is object, write is string https://www.pivotaltracker.com/story/show/165564276
  air_conditioning = "air_conditioning",
  allow_syndication = "allow_syndication",
  apartment_id = "apartment_id",
  application_procedure = "application_procedure",
  apply_token_url = "apply_token_url",
  apt_number = "apt_number",
  area = "area",
  attached_to_another_dwelling = "attached_to_another_dwelling",
  base_melissa_address_key = "base_melissa_address_key",
  bathrooms = "bathrooms",
  bedrooms = "bedrooms",
  beds_count = "beds_count",
  best_time = "best_time",
  broker_notes = "broker_notes",
  building_name = "building_name",
  building_type = "building_type",
  cable = "cable",
  city = "city",
  co_agent_info = "co_agent_info",
  comments = "comments",
  compensation_data = "compensation_data",
  compensation_remarks = "compensation_remarks",
  condo_association_address = "condo_association_address",
  condo_association_name = "condo_association_name",
  contact = "contact",
  contract_type = "contract_type",
  county = "county",
  crossstreet = "crossstreet",
  date_available = "date_available",
  days_on_market = "days_on_market",
  deleaded = "deleaded",
  direct_phone = "direct_phone",
  direct_phone2 = "direct_phone2",
  direct_phone3 = "direct_phone3",
  disclaimer = "disclaimer",
  disclosure = "disclosure",
  dishwasher = "dishwasher",
  docs = "docs",
  doorman = "doorman",
  dom = "dom",
  electricity = "electricity",
  elementary_school = "elementary_school",
  elevator = "elevator",
  email = "email",
  exclusive_by = "exclusive_by",
  expiration_date = "expiration_date",
  external_structures = "external_structures",
  fax = "fax",
  feed_building_name = "feed_building_name",
  financial_reqs = "financial_reqs",
  finished_square_footage = "finished_square_footage",
  first_name = "first_name",
  fitness = "fitness",
  floor = "floor",
  floor_plan_id = "floor_plan_id",
  floor_plan_max_rent = "floor_plan_max_rent",
  floor_plan_max_squarefootage = "floor_plan_max_squarefootage",
  floor_plan_min_rent = "floor_plan_min_rent",
  floor_plan_min_squarefootage = "floor_plan_min_squarefootage",
  floor_plan_name = "floor_plan_name",
  floor_plan_units_available = "floor_plan_units_available",
  floor_types = "floor_types",
  floors_in_unit = "floors_in_unit",
  full_bathrooms = "full_bathrooms",
  furnished = "furnished",
  garage = "garage",
  garage_spaces = "garage_spaces",
  half_bathrooms = "half_bathrooms",
  health_care = "health_care",
  heat = "heat",
  heat_type = "heat_type",
  hide_for_market_bp = "hide_for_market_bp",
  hide_partner_cp = "hide_partner_cp",
  hide_rb_cp = "hide_rb_cp",
  high_school = "high_school",
  hoa_covenant = "hoa_covenant",
  hoa_fee = "hoa_fee",
  hot_water = "hot_water",
  internet = "internet",
  is_agent_listing = "is_agent_listing",
  is_featured = "is_featured",
  is_listing_free = "is_listing_free",
  is_saved_for_user = "is_saved_for_user",
  key_code = "key_code",
  key_info = "key_info",
  la_reviewer = "la_reviewer",
  landlord_application_review = "landlord_application_review",
  landlord_name = "landlord_name",
  landlord_phone = "landlord_phone",
  landlord_email = "landlord_email",
  last_name = "last_name",
  latitude = "latitude",
  laundry = "laundry",
  lease_date = "lease_date",
  lease_term_details = "lease_term_details",
  listing_agent_id = "listing_agent_id",
  listing_agent_id_other = "listing_agent_id_other",
  listing_alert = "listing_alert",
  listing_changes = "listing_changes",
  listing_source_type = "listing_source_type",
  listings_aj_optional_fields = "listings_aj_optional_fields",
  long_term_commission = "long_term_commission",
  longitude = "longitude",
  lot_square_footage = "lot_square_footage",
  main_pic = "main_pic",
  management_co = "management_co",
  max_long_term_commission = "max_long_term_commission",
  max_square_footage = "max_square_footage",
  melissa_address_key = "melissa_address_key",
  mgmt_address = "mgmt_address",
  middle_school = "middle_school",
  min_long_term_commission = "min_long_term_commission",
  min_square_footage = "min_square_footage",
  mls_entry_date = "mls_entry_date",
  mls_id = "mls_id",
  move_in_costs = "move_in_costs",
  move_in_specials = "move_in_specials",
  move_in_specials_tenant = "move_in_specials_tenant",
  neighborhood = "neighborhood",
  neighborhood_id = "neighborhood_id",
  on_market_date = "on_market_date",
  open_house_schedule = "open_house_schedule",
  other_parking = "other_parking",
  other_utility = "other_utility",
  other_application_instructions = "other_application_instructions",
  owner_expenses = "owner_expenses",
  owner_id = "owner_id",
  owner_info = "owner_info",
  owner_pay = "owner_pay",
  owner_pay_amount = "owner_pay_amount",
  parking_fee = "parking_fee",
  parking_options = "parking_options",
  partner_private_notes = "partner_private_notes",
  pet_fee = "pet_fee",
  pet_specs = "pet_specs",
  pet_types = "pet_types",
  pets = "pets",
  pic_count = "pic_count",
  pics = "pics",
  pool = "pool",
  property_features = "property_features",
  property_owner_type = "property_owner_type",
  property_structure = "property_structure",
  proxy_application_review = "proxy_application_review",
  public_transit = "public_transit",
  region = "region",
  region_list = "region_list",
  rent = "rent",
  rented_equipment = "rented_equipment",
  renter_insurance = "renter_insurance",
  restrictions = "restrictions",
  room_types = "room_types",
  school_district = "school_district",
  service_level = "service_level",
  short_term = "short_term",
  showing_instructions = "showing_instructions",
  showing_instructions_listing = "showing_instructions_listing",
  square_footage = "square_footage",
  stage_changes_for_approval = "stage_changes_for_approval",
  state = "state",
  status = "status",
  sublet = "sublet",
  sublet_term = "sublet_term",
  sundeck_grills = "sundeck_grills",
  syndication_allow_address = "syndication_allow_address",
  syndication_targets = "syndication_targets",
  third_party_application_url = "third_party_application_url",
  three_quarter_bathrooms = "three_quarter_bathrooms",
  type = "type",
  type_contact = "type_contact",
  udf = "udf",
  unit_features = "unit_features",
  unit_occupied = "unit_occupied",
  updated_at = "updated_at",
  use_apply_now = "use_apply_now",
  use_third_party = "use_third_party",
  use_paper = "use_paper",
  utilities_available = "utilities_available", // TODO: this is the new field name to get the utilities available for the listing (not necessarily included with rent price)
  utilities_included = "utilities_included", // TODO: old field name needs to be cleaned up
  virtual_showing_allowed = "virtual_showing_allowed",
  virtual_showing_notes = "virtual_showing_notes",
  virtual_tour_url = "virtual_tour_url",
  water = "water",
  wheel_chair = "wheel_chair",
  year_built = "year_built",
  zip = "zip",
  agent_notes = "agent_notes",
  state_list = "state_list",
  showing_time = "showing_time",
  rent_changes = "rent_changes",
  hide_apply_now  = "hide_apply_now ",
  last_updated = "last_updated",
  modified_by = "modified_by"
}

interface IListingApiGetResponse {
  data: {
    data: IListing[];
    meta: any;
  };
}

export interface IListingLocal extends IListing {
  onStreetParking?: boolean;
  offStreetParking?: boolean;
  sharedParking?: boolean;
  coveredExtraFeeParking?: boolean;
  coveredFeeParking?: boolean;
  otherParking?: boolean;
  inBuildingLaundry?: boolean;
  inUnitLaundry?: boolean;
  hookUpsLaundry?: boolean;
  ownerPayAmountValue?: string;
  ownerPayAmountType?: string;
  website_visibility?: string;
  bp_visibility?: string;
  doc_count?: number;
  ownerFullName?: string;
  agent_notes?: string;
  pet_types?: any;
  showing_time?: any;
  listings_aj_optional_fields?: any
  unit_features?: string[];
  floor_types?: string[];
  floors_in_unit?: string[];
}

export interface IRentalLeaseListing extends IListing {
  monthly_rent?: number;
  lease_date?: string;
  lease_term?: number;
  leasing_agent_name?: string;
  leasing_agent_email?: string;
  leasing_agent_phone?: any;
  leasing_brokerage_name?: string;
}

export interface IListingLocalParking {
  onStreetParking: boolean;
  offStreetParking: boolean;
  sharedParking: boolean;
  coveredFeeParking: boolean;
  coveredExtraFeeParking: boolean;
  otherParking: boolean;
}

export function parseParkingOptionsToLocal(
  parkingOptionsString
): IListingLocalParking {
  return {
    onStreetParking: Boolean(
      parkingOptionsString && parkingOptionsString.includes("On Street Parking")
    ),
    offStreetParking: Boolean(
      parkingOptionsString &&
        parkingOptionsString.includes("Off Street Parking")
    ),
    sharedParking: Boolean(
      parkingOptionsString && parkingOptionsString.includes("Shared Driveway")
    ),
    coveredExtraFeeParking: Boolean(
      parkingOptionsString &&
        parkingOptionsString.includes("Covered Parking for extra fee")
    ),
    coveredFeeParking: Boolean(
      parkingOptionsString &&
        parkingOptionsString.includes("Covered Parking for free")
    ),
    otherParking: Boolean(
      parkingOptionsString && parkingOptionsString.includes("Other")
    ),
  };
}

// actype 1 value, heattype 1 value, parkingoptions multiple, laundry =values

export function formatSettingsToLocal(
  settings = {} as IListing
): IListingLocal {
  const ownerPayValues = parseOwnerPayValue(settings["owner_pay_amount"]);
  return {
    ...settings,
    ...parseParkingOptionsToLocal(settings["parking_options"]),
    inBuildingLaundry: Boolean(
      settings["laundry"] && settings["laundry"].includes("In-Building")
    ),
    inUnitLaundry: Boolean(
      settings["laundry"] && settings["laundry"].includes("In-Unit")
    ),
    hookUpsLaundry: Boolean(
      settings["laundry"] && settings["laundry"].includes("Hook-ups")
    ),
    pet_types: parsePetType(settings.pet_types as any),
    air_conditioning: parseBoolean(settings.air_conditioning),
    cable: parseBoolean(settings.cable),
    dishwasher: parseBoolean(settings.dishwasher), //Yes
    electricity: parseBoolean(settings.electricity), //Yes
    heat: parseBoolean(settings.heat), //Yes
    hot_water: parseBoolean(settings.hot_water),
    internet: parseBoolean(settings.internet),
    water: parseBoolean(settings.water),
    sundeck_grills: parseBoolean(settings.sundeck_grills), //Yes
    agent_notes:
      (settings.agent_listing_notes &&
        settings.agent_listing_notes.agent_notes) ||
      "",
    ownerPayAmountValue: ownerPayValues.ownerPayAmountValue,
    ownerPayAmountType: ownerPayValues.ownerPayAmountType,
    // furnished: settings.furnished ? "furnished" : "no_furnished", //Partly furnished
    website_visibility: getWebsiteVisibility(
      settings.hide_partner_cp,
      settings.hide_rb_cp
    ),
    bp_visibility: settings.hide_for_market_bp
      ? "bpVisibilityBrokerAgents"
      : "bpVisibilityAnyAgent",
    doc_count: settings.docs && settings.docs.length,
    ownerFullName:
      settings.management_co !== ""
        ? settings.management_co
        : `${settings.first_name} ${settings.last_name}`,
    floor_plan_max_rent:
      settings.floor_plan_max_rent && settings.floor_plan_max_rent > 0
        ? settings.floor_plan_max_rent
        : null,
    floor_plan_min_rent:
      settings.floor_plan_min_rent && settings.floor_plan_min_rent > 0
        ? settings.floor_plan_min_rent
        : null,
  };
}

export function formatSettingsToLocalV2({
  listing = {},
  usedKeys,
}: {
  listing: IListing;
  usedKeys: EFieldsToInclude[];
}): IListingLocal {
  const localListing = {
    ...listing,
    parking_options:
      listing.parking_options !== "" ? listing.parking_options?.split(",") : [],
    laundry: listing.laundry !== "" ? listing.laundry?.split(",") : [],
    agent_notes:
      (listing.agent_listing_notes &&
        listing.agent_listing_notes.agent_notes) ||
      "",
    website_visibility: getWebsiteVisibility(
      listing.hide_partner_cp,
      listing.hide_rb_cp
    ),
    bp_visibility: listing.hide_for_market_bp
      ? "bpVisibilityBrokerAgents"
      : "bpVisibilityAnyAgent",
    doc_count: listing.docs && listing.docs.length,
  };

  const reducedLocalListing = {};
  usedKeys.forEach((fieldName) => {
    reducedLocalListing[fieldName] = localListing[fieldName];
  });

  if (usedKeys.includes(EFieldsToInclude.owner_pay_amount)) {
    const ownerPayValues = parseOwnerPayValue(listing["owner_pay_amount"]);
    reducedLocalListing["ownerPayAmountValue"] =
      ownerPayValues.ownerPayAmountValue;
    reducedLocalListing["ownerPayAmountType"] =
      ownerPayValues.ownerPayAmountType;
  }

  if (usedKeys.includes(EFieldsToInclude.management_co)) {
    reducedLocalListing["ownerFullName"] =
      listing.management_co !== ""
        ? listing.management_co
        : `${listing.first_name} ${listing.last_name}`;
  }

  if (usedKeys.includes(EFieldsToInclude.renter_insurance)) {
    reducedLocalListing["renter_insurance"] =
      listing.renter_insurance === "Unknown" ? "" : listing.renter_insurance;
  }

  reducedLocalListing["listing_source_type"] = listing.listing_source_type;

    if (usedKeys.includes(EFieldsToInclude.udf)) {
        listing.udf?.map(field => {
            reducedLocalListing[`udf_${field.id}`] = field.value
        });
    }
  return reducedLocalListing;
}

//Can be unit tested
export function parsePetType(petType = ""): PetType[] {
  if (typeof petType !== 'string') {
    return petType
  }
  petType = petType.toLowerCase();
  if (petType.includes("cat")) {
    if (petType.includes("dog")) {
      return [PetType.CATS_DOGS];
    }
    return [PetType.CAT];
  } else if (petType.includes("dog")) {
    return [PetType.DOG];
  } else if (petType.includes("other")) {
    return [PetType.OTHER];
  }
}

//Can be unit tested
function parseBoolean(val) {
  if (val === "true" || val === "yes" || val === "Yes" || val === "1") {
    return true;
  } else if (val === "false" || val === "no" || val === "No" || val === "0") {
    return false;
  }

  return Boolean(val);
}

//Can be unit tested
function getWebsiteVisibility(hidePartnerCp, hideRbCp) {
  if (hidePartnerCp && hideRbCp) {
    return "webVisibilityHide";
  } else if (!hidePartnerCp && !hideRbCp) {
    return "webVisibilityAll";
  } else {
    return hidePartnerCp ? "webVisibilityRB" : "webVisibilityPartner";
  }
}

export function getKeysStartingWithUDF(object) {
    // Filter entries where the key starts with "test_"
    return Object.entries(object).filter(([key, _]) => key.startsWith('udf_'));
}

export function formatSettingsToServer(
  settings: IListingLocal,
  v2Fields = false
) {
  const newSettings = { ...settings };
  const parkingOptions = [];

  if (!v2Fields) {
    if (settings.onStreetParking) parkingOptions.push("On Street Parking");
    if (settings.offStreetParking) parkingOptions.push("Off Street Parking");
    if (settings.sharedParking) parkingOptions.push("Shared Driveway");
    if (settings.coveredExtraFeeParking)
      parkingOptions.push("Covered Parking for extra fee");
    if (settings.coveredFeeParking)
      parkingOptions.push("Covered Parking for free");
    if (settings.otherParking) parkingOptions.push("Other");
    newSettings["parking_options"] = parkingOptions.join(",");

    delete newSettings["onStreetParking"];
    delete newSettings["offStreetParking"];
    delete newSettings["sharedParking"];
    delete newSettings["coveredExtraFeeParking"];
    delete newSettings["coveredFeeParking"];
    delete newSettings["otherParking"];

    const laundryOptions = [];
    if (settings.inBuildingLaundry) laundryOptions.push("In-Building");
    if (settings.inUnitLaundry) laundryOptions.push("In-Unit");
    if (settings.hookUpsLaundry) laundryOptions.push("Hook-ups");
    newSettings["laundry"] = laundryOptions.join(",");

    delete newSettings["inBuildingLaundry"];
    delete newSettings["inUnitLaundry"];
    delete newSettings["hookUpsLaundry"];

    newSettings.furnished =
      settings.furnished === "furnished" ? "furnished" : "no_furnished";
  }

  //The owner_pay_amount comes as a single string from the API, we must divide it to handle it properly on the front end. V2 also.
  newSettings.owner_pay_amount =
    settings.ownerPayAmountType === "%"
      ? settings.ownerPayAmountValue + settings.ownerPayAmountType
      : settings.ownerPayAmountType + settings.ownerPayAmountValue;
  delete newSettings["ownerPayAmountType"];
  delete newSettings["ownerPayAmountValue"];

  newSettings.hide_for_market_bp =
    settings.bp_visibility === "bpVisibilityBrokerAgents";

  delete newSettings["webVisibilityHide"];
  delete newSettings["webVisibilityRB"];
  delete newSettings["webVisibilityPartner"];
  delete newSettings["bpVisibilityBrokerAgents"];

  delete newSettings["agent_listing_notes"];

  if (settings.ownerFullName && settings.ownerFullName !== "") {
    newSettings.management_co = settings.ownerFullName;
    newSettings.first_name = settings.ownerFullName.split(" ")[0];
    newSettings.last_name = settings.ownerFullName
      .split(" ")
      .splice(1)
      .join(" ");
  }
  delete newSettings["ownerFullName"];

  newSettings["finished_square_footage"] =
    settings["finished_square_footage"] === null
      ? ""
      : settings["finished_square_footage"];
  newSettings.half_bathrooms =
    settings.half_bathrooms === null ? "" : settings.half_bathrooms;
  newSettings.three_quarter_bathrooms =
    settings.three_quarter_bathrooms === null
      ? ""
      : settings.three_quarter_bathrooms;
  newSettings.pet_fee = settings.pet_fee === null ? "" : settings.pet_fee;
  newSettings.hoa_fee = settings.hoa_fee === null ? "" : settings.hoa_fee;

  newSettings.use_apply_now = settings.use_apply_now;
  newSettings.use_third_party = settings.use_third_party;
  newSettings.use_paper = settings.use_paper;
  newSettings.proxy_application_review = settings.proxy_application_review;
  newSettings.landlord_application_review = settings.landlord_application_review;
  newSettings.landlord_name = settings.landlord_name;
  newSettings.landlord_phone = settings.landlord_phone;
  newSettings.landlord_email = settings.landlord_email;
  newSettings.third_party_application_url = settings.third_party_application_url;
  newSettings.other_application_instructions = settings.other_application_instructions;

  const udfEntries = getKeysStartingWithUDF(settings);
  const udfObject = Object.fromEntries(udfEntries);
  Object.keys(udfObject).forEach(key => delete newSettings[key]);
  newSettings.udf = Object.entries(udfObject).map(([key, value]) => {
      // Extract the numeric part of the key
      const id = key.match(/\d+/)[0];
      // Construct the new object
      return { id, value };
  });

  newSettings.listings_aj_optional_fields = settings.listings_aj_optional_fields
  return newSettings;
}

export const reviewText =
  "Your changes have been submitted. Our team will review and contact you if we have any questions. After we review, your changes will be visible on our website.";
