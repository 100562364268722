import React, {useContext, useEffect, useState} from "react";
import {EFieldsToInclude, listingApi, ListingType} from "../../../services/api/listing";
import {ListingsControls} from "../../components/listing_search/listings_controls/listings_controls";
import {CircularProgress} from "@material-ui/core";
import {BasicSearch} from "../../components/listing_search/basic_search/basic_search";
import {getEmptyCriteria, ListingSearchContext} from "../../components/listing_search/listing_search_context";
import {BrokerSearchingContext} from "./broker_search_context";
import {BookmarksProvider} from "./bookmarks_context";
import {ListView} from "./components/list_view/list_view";
import {TileView} from "./components/tile_view/tile_view";
import {MapView} from "./components/map_view/map_view";
import {isMobile, ViewType} from "../../utils/device_info";
import {PaginationModule} from "./pagination_module";
import {SweetAlertsProvider} from "../../components/sweet_alert/sweet_alert";
import {SelectedListingsProvider} from "./selected_listings_context";
import {FiltersProvider} from "../../components/listing_search/search_filters/filters_provider";
import "./broker_search.scss"
import {SearchWidget} from "./search_widget";
import {BookmarksView} from "./components/bookmarks_view/bookmarks_view";
import {mapParamsForUI, shortcutsAlertsApi} from "../../../services/rails_api/broker_portal/shortcuts_alerts";
import {AgentUserContext} from "./agent_user_context";
import moment from "moment";
import * as qs from 'query-string';
import { partnerConfigApi } from "../../../services/api/partner_config";
import { RowsPerPagePagination } from './components/map_view/map_view_config/MapRowsPerPage';
import { UsePartnerConfig } from '../../components/hooks/use_partner_config';
import { TabsConfig } from "./components/detail_search_results/tabs_config/tabs_config";
import { isMobileSafari, isAndroid } from "react-device-detect";

export const defaultFieldsToInclude = [
        EFieldsToInclude.building_name,
        EFieldsToInclude.email,
        EFieldsToInclude.zip,
        EFieldsToInclude.last_name,
        EFieldsToInclude.first_name,
        EFieldsToInclude.owner_pay,
        EFieldsToInclude.owner_pay_amount,
        EFieldsToInclude.owner_id,
        EFieldsToInclude.apartment_id,
        EFieldsToInclude.status,
        EFieldsToInclude.date_available,
        EFieldsToInclude.beds_count,
        EFieldsToInclude.rent,
        EFieldsToInclude.city,
        EFieldsToInclude.state,
        EFieldsToInclude.address,
        EFieldsToInclude.pics,
        EFieldsToInclude.listing_agent_id,
        EFieldsToInclude.long_term_commission,
        EFieldsToInclude.square_footage,
        EFieldsToInclude.max_square_footage,
        EFieldsToInclude.min_square_footage,
        EFieldsToInclude.max_long_term_commission,
        EFieldsToInclude.min_long_term_commission,
        EFieldsToInclude.mls_id,
        EFieldsToInclude.type_contact,
        EFieldsToInclude.management_co,
        EFieldsToInclude.agent_info,
        EFieldsToInclude.is_agent_listing,
        EFieldsToInclude.is_listing_free,
        EFieldsToInclude.apt_number,
        EFieldsToInclude.longitude,
        EFieldsToInclude.latitude,
        EFieldsToInclude.bedrooms,
        EFieldsToInclude.bathrooms,
        // --- RB-1111: Days On Market new additional fields ---
        EFieldsToInclude.rent_changes,
        EFieldsToInclude.dom
        // --- END ---
    ];

export function BrokerSearch() {

    //Non context state
    const [totalPagesMap, setTotalPagesMap] = useState(0);
    const [listingsMapArea, setListingsMapArea] = useState([]);
    const [totalPages, setTotalPages] = useState(0);
    const [totalResults, setTotalResults] = useState(0);
    const [noListingsFound, setNoListingsFound] = useState(undefined);
    const [ selectedSortBy,  setSelectedSortBy] = useState<string>("");

    const {
        criteria,
        setCriteria,
        initFilterConfig,
        tabChosen,
        setAllCount,
        setMlsCount,
        setRbCount,
        drawShapePayload,
        isPaginatedFromMapListingArea,
        isPaginatedFromMapArea,
        listingAreaPageNum,
        mapAreaPageNum,
        isActionFromMap,
        setIsActionFromMap,
        setLoadingMapFilterSearch,
        setIsActionExcludeOrDeleteShape,
        initPartnerKey
    } = useContext(ListingSearchContext);

    const {agentInfo, agentId, isMlsAgent, isFullAgent} = useContext(AgentUserContext);
    const {hideMapSearchView} = UsePartnerConfig();
    const [excludedFilters, setExcludedFilters] = useState([]);
    const [filtersOpen, setFiltersOpen] = useState(false);
    const [totalListingCount, setTotalListingCount] = useState<number>(0)

    const [isAddressDisplay, setIsAddressDisplay] = useState<boolean>(false);
    const [isEnableAllListingsTab, setEnableAllListingsTab] = useState <boolean | null>(null);
    const [isEnableMlsListingsTab, setEnableMlsListingsTab] = useState <boolean | null>(null);
    const [isEnableRbListingsTab, setEnableRbListingsTab] = useState <boolean | null>(null);
    const [stateList, setStateList] = useState()
    const [currentZoomLevel, setCurrentZoomLevel] = useState<number>(11)
    const [customStatusesFilters, setCustomStatusesFilters] = useState([]);

    const {
        listings,
        setListings,
        searching,
        setSearching,
        setIsMobileRes,
        viewType,
        setViewType,
        handlePersistLastSearch,
        persistLastSearch,
        setLastSearchId,
        purgeMultipleLocations,
    } = useContext(BrokerSearchingContext);

    const handleResults = (listings, totalResults, listingId, totalPages) => {
        setListings(listings);
        setTotalPages(totalPages);
        setTotalResults(totalResults);
        setLoadingMapFilterSearch(false)
        setIsActionExcludeOrDeleteShape(null)
    };

    const handleResultsMap = (listingsMapArea, totalPagesMap) => {
        if (listingsMapArea.length == 0) {
            setNoListingsFound(true);
        } else {
            setNoListingsFound(false);
        }
        setIsActionFromMap(false)
        setListingsMapArea(listingsMapArea);
        setTotalPagesMap(totalPagesMap);
        setLoadingMapFilterSearch(false)
        setIsActionExcludeOrDeleteShape(null)
    };

    const initWindowResize = () => {
        const resizeListener = () => {
            const mobile = isMobile();
            setIsMobileRes(mobile);
            if (mobile) {
                if (((isFullAgent() && !hideMapSearchView) || (isMlsAgent() && !hideMapSearchView)) && !isMobileSafari && !isAndroid) {
                    setViewType(ViewType.MAP);
                }
            }
        };

        window.addEventListener('resize', resizeListener);

        // clean up function
        return () => {
            window.removeEventListener('resize', resizeListener);
        }
    };

    const handleSearch = async (tabChosen: string, listingShapePayload = {
        geometry: []
    }) => {
        const isPayloadEmpty =  listingShapePayload.geometry.length === 0;

        console.log(tabChosen);
        setLoadingMapFilterSearch(true)
        const filteredPayload = listingShapePayload.geometry.map(({
                uniqueShapeId,
                ...rest
        }) => rest)

        const newPayload = {
            geometry: filteredPayload
        }
        let listingType = criteria.listing_type;
        switch (tabChosen) {
            case 'all':
                break;
            case 'mls':
                listingType = ListingType.mls;
                break;
            case 'rb':
                listingType = ListingType.rb;
                break;
        }

        const listingInitialCount = (viewType === ViewType.MAP) ? isMobile() ? 10 : 50 : criteria?.max_record_count;
        const pageNumListingSetting = (isPaginatedFromMapArea) ? listingAreaPageNum : criteria?.page_number;
        const showGroupedListingsOnMap = (viewType === ViewType.MAP) ? 'map' : '';
        setCriteria({...criteria,  state_list: stateList });
        const pagedCriteria = {
          ...criteria,
          group_by_address: true,
          include_disclaimer: true,
          multiple_areas: purgeMultipleLocations(criteria),
          listing_type: listingType,
          rb_portal: "bp_search",
          load_type_counters: true,
          super_region: criteria.super_region || agentInfo.super_region,
          fields_to_include: defaultFieldsToInclude,
          max_record_count: listingInitialCount,
          page_number: pageNumListingSetting,
          search_view: showGroupedListingsOnMap
        };

        const response = isPayloadEmpty ?
                        await listingApi.get(pagedCriteria) :
                        await listingApi.getListingsInShape(pagedCriteria, newPayload);

        const listings = response.data?.data || [];

        // This will contain all the listings on the map section when map view is accessed
        if (!isPaginatedFromMapListingArea && isPayloadEmpty && viewType === ViewType.MAP) {
            let maxRecordCountMap: number;
            if (viewType === ViewType.MAP && tabChosen == 'all' && response.data.meta.total_records_all > RowsPerPagePagination.LIMIT ||
                viewType === ViewType.MAP && tabChosen == 'mls' && response.data.meta.total_records_mls > RowsPerPagePagination.LIMIT ||
                viewType === ViewType.MAP && tabChosen == 'rb' && response.data.meta.total_records_rb > RowsPerPagePagination.LIMIT
            ) {
                maxRecordCountMap = RowsPerPagePagination.LIMIT;
            } else {
                maxRecordCountMap = RowsPerPagePagination.ALL;
            }
            setCriteria({...criteria,  state_list: stateList });
            const pagedCriteriaMap = {
                ...criteria,
                group_by_address: true,
                include_disclaimer: true,
                multiple_areas: purgeMultipleLocations(criteria),
                listing_type: listingType,
                rb_portal: "bp_search",
                super_region: criteria.super_region || agentInfo.super_region,
                fields_to_include: defaultFieldsToInclude,
                max_record_count: maxRecordCountMap,
                page_number: mapAreaPageNum,
                search_view: showGroupedListingsOnMap
            };

            const responseMap = await listingApi.get(pagedCriteriaMap);
            const listingsMapData = responseMap.data?.data || [];
            const totalPagesMapData = responseMap.data.meta.total_pages
            handleResultsMap(listingsMapData, totalPagesMapData);
        }
        // When we draw shape on the map, this will update the listings on the map area
        if (response.data.meta.total_records === 0) {
            setNoListingsFound(true);
        } else {
            setNoListingsFound(false);
        }
        if (!isPayloadEmpty) {
            handleResultsMap(listings, response.data.meta.total_pages);
        }
        // tile view listings
        handleResults(listings, response.data.meta.total_records, criteria.listing_ids, response.data.meta.total_pages);

        setAllCount(response.data.meta.total_records_all);
        setMlsCount(response.data.meta.total_records_mls);
        setRbCount(response.data.meta.total_records_rb);

        setSearching(false);

        if(persistLastSearch) {
            handlePersistLastSearch();
        }
    };

    const fetchPartnerInfo = async (partnerKey: string) => {
        try {
            const partnerInfo = await partnerConfigApi.get(partnerKey, [
                "broker_portal_settings", "front_end_settings"
            ])

            const agentSearchsettings = JSON.parse(
                partnerInfo.broker_portal_settings.search_settings
            );

            let customStatuses = partnerInfo.broker_portal_settings?.custom_statuses
            if (partnerKey === 'armls' && customStatuses) {
                const customStatusesSettings = JSON.parse(customStatuses);
                const customStatusesKeys = Object.keys(customStatusesSettings.custom_statuses[0]);
                //console.log('Custom Statuses Keys', customStatusesKeys);
                setCustomStatusesFilters(customStatusesKeys);
            }

            setIsAddressDisplay(
                agentSearchsettings.display_listing_address
            )
            setEnableAllListingsTab(
                agentSearchsettings.enable_all_listings_tab
            )
            setEnableMlsListingsTab(
                agentSearchsettings.enable_mls_listings_tab
            )
            setEnableRbListingsTab(
                agentSearchsettings.enable_rb_listings_tab
            )

            if(partnerInfo.front_end_settings?.default_location?.state_list){
                const list = JSON.parse(partnerInfo.front_end_settings.default_location.state_list);
                setStateList(list)
            }

        } catch (error) {
            throw error
        }
    }

    const initLastSearch = async () => {
        const params = {
            agent_user_id: agentId,
        };

        const res = await shortcutsAlertsApi.get(params);
        if(200 === res.status) {
            const arr = res.data.filter(shortcut => shortcut.criteria_name === "last_search");
            if(arr.length) {
                const lastSearch = arr[0];
                const newCriteria = mapParamsForUI({...lastSearch.search_params, partner_key: criteria.partner_key});
                setTimeout(() => {
                    setCriteria({...newCriteria,  agent_id: agentId, rb_portal: "bp_search" });
                    setLastSearchId(lastSearch.id);
                    setSearching(true);
                }, 3000);
            }
            else {
                setSearching(true);
            }
        }
    };

    const initQueryParamsSearch = async () => {
        const query: any = qs.parse(window.location.search);
        query.multiple_areas = JSON.parse(query.multiple_areas);
        if (query.available_from_date && query.available_from_date !== 'null' ) {
            query.available_from_date = moment(query.available_from_date).format('MM/DD/YY');
        } else {
            delete query.available_from_date
        }
        if (query.available_by_date && query.available_by_date !== 'null' ) {
            query.available_by_date = moment(query.available_by_date).format('MM/DD/YY');
        } else {
            delete query.available_by_date
        }
        query.pets = query.pets && query.pets === 'true';
        query.garage = query.garage && query.garage === 'true';
        query.rent_range = {min_price: query.min_price, max_price: query.max_price};
        setTimeout(() => {
            setCriteria({...getEmptyCriteria(criteria.partner_key), ...query, agent_id: agentId, rb_portal: "bp_search"});
            window.history.replaceState(null, null, window.location.pathname);
            setFiltersOpen(true);
            setSearching(true);
        }, 4000)
    };

    useEffect(() => {
        const queryParams = window.location.search;
        if (agentId > 0) {
            if (queryParams === "") {
                initLastSearch();
            } else {
                initQueryParamsSearch();
            }
        }
    }, [agentId]);

    useEffect(() => {
        if (agentInfo) {
            initPartnerKey();
            initFilterConfig();
            initWindowResize();

            if (isMlsAgent()) {
                setExcludedFilters([...excludedFilters])
            }
        }
    }, [agentInfo]);

    useEffect(() => {
        if ((searching && tabChosen && criteria.page_number) ||
            (drawShapePayload.geometry.length > 0)  ||
            isActionFromMap
            ) {
                setNoListingsFound(false);
                handleSearch(tabChosen, drawShapePayload);
            }
    }, [searching, tabChosen, criteria.page_number, drawShapePayload, isActionFromMap]);

    useEffect(() => {
        if (criteria.partner_key) {
            fetchPartnerInfo(criteria.partner_key);
        }
    }, [criteria.partner_key]);

    useEffect(() => {
        if (!isMobile()) {
            document.querySelector('html').style.width = 'fit-content'
        }

        return () => {
            document.querySelector('html').style.width = null
        }
    }, [])

    return (
        <SelectedListingsProvider>
            <BookmarksProvider>
                <SweetAlertsProvider>
                    <FiltersProvider excludedFilters={excludedFilters} customStatusesFilters={customStatusesFilters}>
                        <div className="broker-search--container">
                            {
                                viewType === ViewType.BOOKMARKS ?
                                    null :
                                    <div>
                                        <BasicSearch filtersOpen={filtersOpen} noListingsFound={noListingsFound} customStatusesFilters={customStatusesFilters}/>
                                    </div>
                            }
                            <div className="listing-controls-row">
                                <ListingsControls />
                                <SearchWidget
                                    setTotalListingCount={setTotalListingCount}
                                />
                            </div>
                            <div className="search-result-border" id="search-result-border">
                                <div className="search-results-cont">
                                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                                    <TabsConfig
                                        totalResults={totalResults}
                                        isEnableAllListingsTab={isEnableAllListingsTab}
                                        isEnableMlsListingsTab={isEnableMlsListingsTab}
                                        isEnableRbListingsTab={isEnableRbListingsTab}
                                    />
                                    { (viewType === ViewType.TILE ||  viewType === ViewType.LIST) && (
                                        <PaginationModule totalPages={totalPages} totalResults={totalResults} />)
                                    }
                                </div>
                                {
                                    viewType === ViewType.MAP ?
                                        searching ?  <div className="spinner-container"><CircularProgress disableShrink className="spinner" /></div>
                                        :
                                        <MapView
                                            listings={listings}
                                            isAddressDisplay={isAddressDisplay}
                                            isEnableAllListingsTab={isEnableAllListingsTab}
                                            isEnableMlsListingsTab={isEnableMlsListingsTab}
                                            isEnableRbListingsTab={isEnableRbListingsTab}
                                            totalPages={totalPages}
                                            totalResults={totalResults}
                                            selectedSortBy={selectedSortBy}
                                            setSelectedSortBy={setSelectedSortBy}
                                            totalListingCount={totalListingCount}
                                            listingsMapArea={listingsMapArea}
                                            totalPagesMap={totalPagesMap}
                                            currentZoomLevel={currentZoomLevel}
                                            setCurrentZoomLevel={setCurrentZoomLevel}
                                        /> :
                                    viewType === ViewType.BOOKMARKS ?
                                        <BookmarksView />
                                        : <>
                                            {
                                                searching ?
                                                    <div className="spinner-container"><CircularProgress disableShrink className="spinner"/></div> :
                                                    listings.length > 0 ?
                                                        viewType === ViewType.LIST ?
                                                            <ListView
                                                            listings={listings}
                                                            isAddressDisplay={isAddressDisplay}
                                                            totalListingCount={totalListingCount}
                                                            />
                                                            : <TileView
                                                                listings={listings}
                                                                isAddressDisplay={isAddressDisplay}
                                                                isEnableAllListingsTab={isEnableAllListingsTab}
                                                                isEnableMlsListingsTab={isEnableMlsListingsTab}
                                                                isEnableRbListingsTab={isEnableRbListingsTab}
                                                                totalPages={totalPages}
                                                                totalResults={totalResults}/>
                                                        : null
                                            }
                                            { !searching && <PaginationModule totalPages={totalPages} totalResults={totalResults} /> }
                                        </>
                                }
                                {
                                    noListingsFound !== undefined && noListingsFound ?
                                        <div className="no-listings">
                                            <i className="fas fa-exclamation-triangle" style={{marginRight: 10}}/> There are no listings matching your search criteria
                                        </div> : null
                                }
                                </div>
                            </div>
                        </div>
                    </FiltersProvider>
                </SweetAlertsProvider>
            </BookmarksProvider>
        </SelectedListingsProvider>
    )
}
